import { useCallback } from "react";
import { VIEWS, VIEW_DURATION_DAYS } from "../constants/index";
import { ButtonToolbar, Button, ButtonGroup } from "rsuite";

const CALENDAR_NAV_BUTTONS = [
  { label: "<", action: "prev" },
  { label: ">", action: "next" },
];
export const DateControl = ({
  calendarView,
  activeView,
  onDateChange,
  changeCalendarView,
}) => {
  const onDateNavBtnClick = useCallback(
    (action) => () => {
      const api = calendarView?.view?.calendar;
      api[action]?.();
      onDateChange?.(api.getDate());
    },
    [onDateChange, calendarView]
  );
  return (
    <div className="days-controls">
      <div className="day-control">
        {CALENDAR_NAV_BUTTONS.map((btn) => (
          <Button
            key={btn.label}
            className="fc-button fc-button-primary fc-button"
            aria-pressed="false"
            title={btn.label}
            onClick={onDateNavBtnClick(btn.action)}
          >
            {btn.label}
          </Button>
        ))}
      </div>
      <ButtonToolbar>
        <ButtonGroup size="xs" className="day-control">
          {Object.keys(VIEWS).map((key) => (
            <Button
              key={key}
              onClick={() => {
                changeCalendarView(key);
              }}
              className="day-control-button"
              color={VIEWS[key] === activeView ? "blue" : undefined}
            >
              {VIEW_DURATION_DAYS[VIEWS[key]]}
            </Button>
          ))}
        </ButtonGroup>
      </ButtonToolbar>
    </div>
  );
};
