import { IconButton, Icon } from "rsuite";
import { ReferenceDisplay } from "../../../reference/react-reference-display";
import { EVENT_VIEW } from "../constants/index";

const HomeEvent = ({ event }) => {
  const { address } = event.extendedProps;
  return [address.city, address.addressLine1, address.addressLine2]
    .filter(Boolean)
    .join(", ");
};

const TravelEvent = ({ event }) => {
  const {
    extendedProps: { distance, duration },
  } = event;
  return (
    <>
      <div className="travel-vr" />
      <div className="event-text">
        <span>
          <span>{distance?.text}</span>/<span>{duration?.text}</span>
        </span>
      </div>
    </>
  );
};

const AccommodationShadowEvent = ({ event }) => {
  return (
    <span className="event-text">
      <i>{event.title}</i>
    </span>
  );
};
const AccommodationEvent = ({ timeText, event, children }) => {
  const { city, fare, roomCategory, country } = event.extendedProps;
  return (
    <>
      <div className="event-text">
        <b>{timeText}</b>
        <div>{event.title}</div>
        <div>
          <i>
            {city} {country}
          </i>
        </div>
        <div>
          <ReferenceDisplay reference={roomCategory} />{" "}
          <ReferenceDisplay reference={fare} />
        </div>
      </div>
      {children}
    </>
  );
};

const ServiceEvent = ({ timeText, event, children }) => (
  <>
    <span className="event-text">
      <b>{timeText}</b>
      <i>{event.title}</i>
    </span>
    {children}
  </>
);

const CalendarEvent = React.memo(({ event, timeText, onDeleteEvent }) => {
  const { eventView, isAccommodation } = event.extendedProps;

  switch (eventView) {
    case EVENT_VIEW.TRAVEL: {
      return <TravelEvent event={event} />;
    }
    case EVENT_VIEW.ACCOMMODATION_SHADOW:
      return <AccommodationShadowEvent event={event} />;
    case EVENT_VIEW.HOME: {
      return <HomeEvent event={event} />;
    }
    case EVENT_VIEW.ACCOMMODATION:
    case EVENT_VIEW.SERVICE: {
      const Event = isAccommodation ? AccommodationEvent : ServiceEvent;
      return (
        <Event timeText={timeText} event={event}>
          <IconButton
            onClick={() => onDeleteEvent?.(event.id)}
            appearance="subtle"
            size="xs"
            placement="right"
            icon={<Icon icon="close" />}
            className="delete-button"
          />
        </Event>
      );
    }
    default: {
      return <></>;
    }
  }
});

export default CalendarEvent;
